@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    p {
        @apply text-slate-600 text-sm  sm:!text-lg !leading-6;
    }

    /* h1 {
        @apply text-3xl font-extrabold tracking-tight text-slate-900 lg:text-5xl dark:text-white;
    } */

    h2 {
        @apply text-3xl font-extrabold tracking-tight text-slate-900 lg:text-5xl dark:text-white;
    }

    h3 {
        @apply pt-4 text-xl font-bold tracking-tight text-slate-900 dark:text-white;
    }

    @media (max-width: 991px) {
        .swiper-slide {
          width: 80%;
        }
      }

      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
      }

  @media (prefers-color-scheme: dark) {
    body {
      background: #fff!important;
      color: #000!important;
    }
  }
      
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  




}

::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, 0.5);
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  border-radius: 10px;
}

 .modal-inner p, .modal-inner li {
   word-wrap: break-word;
   line-height: 1.9;
   color: #444;
   font-size: .9rem!important;
 }


 @media(min-width:768px) {
  .modal-inner p, .modal-inner li {
    text-align: justify;
  }
 }


.modal-inner h6 {
  font-weight: 700;
  font-size: 1rem;
  padding-bottom: 20px;
  color: black;
}



.hero-image1{
  right: 200px;
  top: 270px;
  animation:  move 5s linear infinite;
  -webkit-animation:  move 5s linear infinite;
}
.hero-image2{
  right:420px;
  top: 300px;
  animation:  move 4s linear infinite;
  -webkit-animation:  move 4s linear infinite;
}
.hero-image3{
  right: 230px;
  top: 420px;
  animation:  move 6s linear infinite;
  -webkit-animation:  move 6s linear infinite;
}
.hero-image4{
  right: 460px;
  top: 450px;
  animation:  move 4s linear infinite;
  -webkit-animation:  move 4s linear infinite;
}
.hero-image5{
  right: 300px;
  top: 590px;
  animation:  move 5s linear infinite;
  -webkit-animation:  move 5s linear infinite;
}
.hero-image1-shadow{
  right: 250px;
  top: 340px;
  animation:  moveshadow 5s linear infinite;
  -webkit-animation:  moveshadow 5s linear infinite;
}
.hero-image2-shadow{
  right:460px;
  top: 370px;
  animation:  moveshadow 3s linear infinite;
  -webkit-animation:  moveshadow 3s linear infinite;
}
.hero-image3-shadow{
  right: 280px;
  top: 510px;
  animation:  moveshadow 4s linear infinite;
  -webkit-animation:  moveshadow 4s linear infinite;
}
.hero-image4-shadow{
  right: 510px;
  top: 520px;
  animation:  moveshadow 3s linear infinite;
  -webkit-animation:  moveshadow 3s linear infinite;
}
@keyframes move{
  0%{
    transform: translate(-10px,0px);
    -webkit-transform: translate(-10px,0px);
    -moz-transform: translate(-10px,0px);
    -ms-transform: translate(-10px,0px);
    -o-transform: translate(-10px,0px);
}
50%{
  transform: translate(0px,-7px);
  -webkit-transform: translate(0px,-7px);
  -moz-transform: translate(0px,-7px);
  -ms-transform: translate(0px,-7px);
  -o-transform: translate(0px,-7px);
}
100%{
  transform: translate(-10px,0px);
  -webkit-transform: translate(-10px,0px);
  -moz-transform: translate(-10px,0px);
  -ms-transform: translate(-10px,0px);
  -o-transform: translate(-10px,0px);
}
}
@keyframes moveshadow{
  0%{
    transform: translate(-8px,0px);
    -webkit-transform: translate(-8px,0px);
    -moz-transform: translate(-8px,0px);
    -ms-transform: translate(-8px,0px);
    -o-transform: translate(-8px,0px);
}
50%{
  transform: translate(0px,-7px);
  -webkit-transform: translate(0px,-7px);
  -moz-transform: translate(0px,-7px);
  -ms-transform: translate(0px,-7px);
  -o-transform: translate(0px,-7px);
}
100%{
  transform: translate(-8px,0px);
  -webkit-transform: translate(-8px,0px);
  -moz-transform: translate(-8px,0px);
  -ms-transform: translate(-8px,0px);
  -o-transform: translate(-8px,0px);
}
}

input, select, textarea {
  @apply !w-full !p-3 !text-sm !border-gray-200 !bg-gray-50 !rounded-lg
}




/* RangeInput.css */
.range-container {
  position: relative;
}

/* Add custom styles for the step indicator */
.range-step-indicator {
  pointer-events: none;
}

input[type="range"]::-webkit-slider-thumb {
  position: relative !important;
  -webkit-appearance: none; /* Override default look */
  appearance: none;
   /* Centers thumb on the track */
  background-color: #1f4fb6;
  height: 30px;
  width: 30px;    
}

@media (min-width: 1024px) {
  h2 {
    font-size: 2.75rem;
    line-height: 1;
}
}
